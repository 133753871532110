@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;500;700&display=swap');

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Inter', sans-serif;
}

body,
button,
input,
select,
textarea {
    font-family: 'Inter', sans-serif;
}

h1 {
    font-weight: 800;
    font-size: 1.75rem;
    line-height: 100%;
    /* identical to box height, or 28px */
    letter-spacing: -0.4px;
}

h2 {
    font-weight: 800;
    font-size: 1.5rem;
    line-height: 140%;
    /* identical to box height, or 34px */
    letter-spacing: -0.3px;
}

h3, h4 {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 150%;
    letter-spacing: -0.2px;
}

h6{
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.063rem;
    letter-spacing: 0.3px;
}

.body, .body-bold,
.body-large, .body-large-bold,
.body-small, .body-small-bold {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
}

.body-bold {
    font-weight: 700;
}

.body-large, .body-large-bold {
    font-size: 1rem;
}

.body-large-bold {
    font-weight: 700;
}


.body-small, .body-small-bold {
    font-size: 0.75rem;
}

.body-small-bold {
    font-weight: 700;
}

.f-urbanist{
	font-family: "Urbanist", sans-serif;
}

.f-lato{
	font-family: "Lato", sans-serif;
}

.f-10px{
	font-size: 12px;
}

.f-12px{
	font-size: 12px;
}

.f-14px{
	font-size: 14px;
}

.f-18px{
	font-size: 18px;
}

.f-20px{
	font-size: 20px;
}

.f-30px{
	font-size: 30px;
}

.t-neutral-300{
	color:#6F7386 !important;
}
.t-neutral-250{
	color:#5D627B !important;
}

.t-neutral-200{
	color: #AFB4D0 !important;
}

.ls-1-6{
	letter-spacing: 1.6px;
}

.ls-1-2{
	letter-spacing: 1.2px;
}

.body.small{
	font-size: 14px;
	line-height: 20px;
	font-family: Urbanist, 'sans-serif';
	opacity: 0.5;
	font-weight: bold;
}

.body.small li{
	margin-bottom: 1rem;
}

.body{
	font-size: 16px;
	line-height: 36px;
	font-family: Urbanist, 'sans-serif';
}

.body p{
	font-size: 16px;
	line-height: 36px;
	margin-bottom: 2rem;
}

.body a{
	color: #AFB4D0;
}

.embed{
	width: 100%;
}

.embed iframe{
	width: 100%;
	height: 100%;
	border-radius: 1rem;
	aspect-ratio: 16/9;
}
